import { BasicLoanDto, MessageThreadDto } from "src/backend";
import { useMessagesContext } from "src/contexts/messages-context";

export const useMessagesSearchInput = () => {
    const messagesContext = useMessagesContext();

    const onClearSearch = () => {
        messagesContext.onFilterQueryChange('', true);
    }

    const loan = messagesContext.loan;
    const activeThread = messagesContext.activeThread;

    return {
        onFilterQueryChange: (value: string) => messagesContext.onFilterQueryChange(value, true),
        filterQuery: messagesContext.filterQuery,
        onClearSearch,
        searchPlaceholder: getSearchPlaceholder(loan, activeThread),
    } as const
};

const getSearchPlaceholder = (loan: Pick<BasicLoanDto, "projectName">, thread: MessageThreadDto) => {
    // if we have a thread, we are in a thread view
    if (thread?.locked) {
        return `Search Private Chat Messages`;
    } else if (thread) {
        return `Search ${thread.title} Messages`;
    } else if (loan) {
        return `Search ${loan.projectName} Messages`;
    }
    return 'Search Messages';
}