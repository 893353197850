import HighlightText from 'components/dist/atoms/HighlightText';
import Skeleton from 'components/dist/atoms/Skeleton';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { SearchResultDTO } from "src/backend";
import { NextLinkComposed } from "src/components/common/next-link-composed";

interface SearchAutocompleteResultsProps {
    query: string
    noResults: boolean
    isFetching: boolean
    results: SearchResultDTO[]
}
export const SearchAutocompleteResults = (props: SearchAutocompleteResultsProps) => {

    return props.query && <div className='divide-y flex w-full max-h-80 overflow-auto scrollbar-stable flex-col gap-2 scrollbar-stable'>
        {props.noResults && <Text size='sm'>No results found.</Text>}
        {props.isFetching && <Stack space='md'>
            <Skeleton className='h-12' />
            <Skeleton className='h-12' />
            <Skeleton className='h-12' />
        </Stack>}
        {!props.isFetching && props.results.map((result, index) => (<NextLinkComposed
            className='hover:bg-black-10 py-2'
            to={result.link}
            key={`${index}-${result.header}`}>
            <Text className="text-left w-full" weight="medium" size="sm" variant="blue">
                <HighlightText text={result.header} highlightText={String(props.query)} />
            </Text>
            <Text size='sm' className="w-full">
                <HighlightText text={result.hitText} highlightText={String(props.query)} />
            </Text>
        </NextLinkComposed>))}
    </div>
};