import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Stack from 'components/dist/atoms/Stack';

import { useSearchAutocomplete } from './search-autocomplete.state';
import { SearchAutocompleteResults } from './search-autocomplete-results.component';

export const SearchAutocomplete = () => {
    const state = useSearchAutocomplete();

    return <Stack items='center' justify='center' className='w-full group'>
        <Input
            onChange={state.onInputChange}
            value={state.query}
            className='text-sm'
            placeholder={state.placeholder}
            wrapClassName='w-full bg-gray-neutral-30 border border-gray-neutral-30 focus-within:bg-white focus-within:border-blue-100 rounded-full h-10 z-splash relative' >
            <Input.Prefix>
                <Icon name="Search" width={18} height={18} strokeWidth={1.5} />
            </Input.Prefix>
            {state.query && <Input.Suffix>
                <Button
                    onClick={state.onClearSearchClick}
                    className='p-0'
                    size='sm'
                    variant='ghost' circle>
                    <Icon name="Cancel" className='text-black-primary' width={20} height={20} strokeWidth={1.5} />
                </Button>
            </Input.Suffix>}
        </Input>
        <div className='w-full shadow rounded-b-lg rounded-t-2xl top-[56px] bg-white max-w-2xl absolute pt-14 z-tooltip hidden group-focus-within:flex px-4 pb-4'>
            <SearchAutocompleteResults
                query={state.query}
                noResults={state.noResults}
                isFetching={state.isFetching}
                results={state.results.items}
            />
        </div>
    </Stack>
};