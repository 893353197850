
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import FilesDropContainer from 'components/dist/molecules/FilesDropContainer';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import React from 'react';

import { useFormElementsAddState } from './form-elements-add.hook';


export const FormElementsAdd = (props: React.PropsWithChildren<object>) => {
    const state = useFormElementsAddState();
    return <>
        <FilesDropContainer
            ref={state.uploadFilesRef}
            onFilesDrop={state.onDropFiles}
            multiple
        />
        <FilesDropContainer
            ref={state.uploadFoldersRef}
            uploadFolders
            onFilesDrop={state.onDropFiles}
            multiple
        />
        <ScreenSizeDropdownMenu
            modal={false}>
            <ScreenSizeDropdownMenu.Trigger asChild>
                {props.children}
            </ScreenSizeDropdownMenu.Trigger>
            <ScreenSizeDropdownMenu.Content
                align='start'
                side='bottom'
                className="w-full p-4 sm:w-72 z-tooltip absolute gap-3">
                {state.dropdownItems.map((item) => {
                    if (item.type === 'SEPARATOR') {
                        return <Separator key={item.name} />
                    }
                    return (<ScreenSizeDropdownMenu.Item
                        key={item.name}
                        className='p-0'
                        onSelect={item.action}
                    >
                        <Stack row space="sm" className="h-10 p-2 items-center w-full cursor-pointer" >
                            <Icon
                                strokeWidth={1.5}
                                name={item.iconName}
                                width={20}
                            />
                            <Text size="sm">{item.name}</Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>)
                })}
            </ScreenSizeDropdownMenu.Content>
        </ScreenSizeDropdownMenu>
    </>;
}
