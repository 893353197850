import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import { BorrowerPortalMessagesSearchResults } from 'src/components/borrower-portal/borrower-portal-messages/borrower-portal-messages-search-results';

import { SearchAutocompleteResults } from '../search-autocomplete/search-autocomplete-results.component';
import { useSearchMobile } from './search-mobile.state';

export const SearchMobile = () => {
    const state = useSearchMobile();

    return <Dialog
        open={state.isOpened}
        onOpenChange={state.setIsOpened}
    >
        <Dialog.Trigger asChild>
            <Button size='xs' variant='ghost' circle>
                <Icon name="Search" width={20} height={20} strokeWidth={1.5} />
            </Button>
        </Dialog.Trigger>
        <Dialog.Content className='w-full h-full overflow-hidden'>
            <div className='flex items-center justify-between w-full p-3'>
                <Dialog.Close asChild>
                    <Button size='xs' variant='ghost' circle>
                        <Icon name="NavArrowLeft" width={20} height={20} strokeWidth={1.5} />
                    </Button>
                </Dialog.Close>
                <input
                    className='w-full h-10 text-sm focus:outline-none'
                    onChange={state.onInputChange}
                    value={state.query}
                    type='text'
                    placeholder='Search' />
                {state.query && <Button
                    onClick={state.onClearSearch}
                    size='xs'
                    variant='ghost' circle>
                    <Icon name="Cancel" width={20} height={20} strokeWidth={1.5} />
                </Button>}
            </div>
            {!state.isSearchMessages && <div className='flex-1 overflow-x-auto scrollbar-stable px-3'>
                <SearchAutocompleteResults
                    query={state.query}
                    noResults={state.noResults}
                    isFetching={state.isFetching}
                    results={state.items}
                />
            </div>}
            {state.isSearchMessages && <div className='px-2 flex flex-col gap-2 overflow-hidden'>
                <BorrowerPortalMessagesSearchResults />
            </div>}
        </Dialog.Content>
    </Dialog>
};